import React from "react";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ThemeLogo from "../../assets/Images/BrandLogo/Logo.png";
import { useState } from "react";
import Langague  from "./Langague"
const Header = () => {
  const [showDropdownC, setShowDropdownC] = useState(false);
  const [isOpen, setIsopen] = useState(false);
  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  }
  return (
    <>
      <div className="header sticky-top">
        <Navbar expand="lg" collapseOnSelect>
          <div className="container">
            <div className="inner-header align-items-center">
              <Navbar.Brand href="/home">
                <img
                  src={ThemeLogo}
                  alt="themeLogo"
                  width="160px"
                  className="img-fluid mx-auto WLogo"
                />
              </Navbar.Brand>
              <Button
                className="btn-navbar navbar-toggle"
                onClick={ToggleSidebar}
              >
                <i className="fa fa-bars" aria-hidden="true"></i>
              </Button>
              <div className={`sidebar ${isOpen === true ? "active" : " "}`}>
                <div id="mySidenav" className="sidenav menu-vertical">
                  <div className="close-nav hidden-md hidden-lg hidden-xl  ">
                    <div>
                      <span>Menu</span>
                    </div>
                    <div>
                      <Button
                        className="closebtn pull-right"
                        onClick={ToggleSidebar}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </Button>
                    </div>
                  </div>
             
                <div className="navDiv">
                  <Nav className="justify-content-center" style={{ flex: 1 }}>
                    <Nav.Link href="home">
                      <Link to="/home"> Services </Link>
                    </Nav.Link>
                      <Nav.Link href="#service">
                      <Link to="/service">Information</Link>
                    </Nav.Link>
                    <Nav.Link href="#portfolio">
                      <Link to="/portfolio">Contact Us</Link>
                    </Nav.Link>
                    <Nav.Link href="#aboutus">
                      <Link to="/aboutus">Processing Time</Link>
                    </Nav.Link>
                    <Nav.Link href="#signin">
                      <Link to="/signin">Partner</Link>
                    </Nav.Link>
                    <NavDropdown
                      title={
                        <div className="pull-left">
                           <img loading="lazy" width="20" src="https://flagcdn.com/w20/us.png" srcSet="https://flagcdn.com/w40/fr.png 2x" alt="" className="mx-1" />
                           English
                        </div>
                    } 
                      id="collasible-nav-dropdown"
                      show={showDropdownC}
                      onMouseLeave={() => setShowDropdownC(false)}
                      onMouseOver={() => setShowDropdownC(true)}
                    >
                    {countries.map(countrie=>(
                          <NavDropdown.Item href="#faq" key={countrie.id}>
                            <Link to="/faq">
                            <img
                              loading="lazy"
                              width="20"
                              src={`https://flagcdn.com/w20/${countrie.code.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${countrie.code.toLowerCase()}.png 2x`}
                              alt=""
                              className='mx-1'
                            /> 
                            {countrie.label}
                            </Link>
                          </NavDropdown.Item>
                    ))}
                      
                    </NavDropdown> 
                  </Nav>
                </div>
                </div>
              </div>
              <div
                className={`sidebar-overlay ${isOpen === true ? "active" : ""}`}
                onClick={ToggleSidebar}
              ></div>
            </div>
          </div>
        </Navbar>
      </div>
    </>
  );
};
export default Header;
const countries = [
  {
    code: 'FR',
    label: 'Français',
    phone: '33',
    id: '1',
  },
  {
    code: 'US',
    label: 'English',
    phone: '1',
    id:"2",
   },
   {
      code: 'SA',
      label: 'Arabe',
      phone: '1',
      id:"3",
    },
];
