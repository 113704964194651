import React,{useEffect} from "react";
import { Row, Col } from "react-bootstrap";
import Form from './Form' 
import Aos from "aos";
import "aos/dist/aos.css";
export default function ContactUs() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="aboutUsBannerImg">
        <div className="container">
          <div className="contactUs">
            <Row>
              <Col xl={6} md={6} lg={6} sm={12}>
                <Form />
              </Col>
              <Col xl={6} md={6} lg={6} sm={12}>
                <div className="quickContact">
                  <h2 >Quick Contect</h2>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting is the orem Ipsum has been the industry's
                    standard dummy text ever sincethe 1500s
                  </p>
                  <div className="contactUsIconContain">
                    <i className="fa fa-map-marker"></i>
                    <span>
                      60, 29th Street, San Francisco, CA 94110 Union Centre,
                      United States of America
                    </span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fas fa-phone-alt"></i>
                    <span>0261-12345</span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fas fa-envelope"></i>
                    <span>demo@demo.com</span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
