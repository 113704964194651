import React from "react";
import Slider from "react-slick";
import { Card, Col } from "react-bootstrap";
import ServicesCard1 from "../../../assets/Images/OurBestServices/ServicesCard1.png";
import ServicesCard2 from "../../../assets/Images/OurBestServices/ServicesCard2.png";
import ServicesCard3 from "../../../assets/Images/OurBestServices/ServicesCard3.png";
import { Link } from "react-router-dom";
const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 1900,
  autoplay: true,
  autoplaySpeed: 700,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
export default function OurBestServices() {
  return (
    <>
      <div className="ourBestServicesBanner">
        <div className="container">
          <div className="ourBestServices text-center">
            <div className="titleFont">
              <h3>Our Mission</h3>
              <h4> Résolution des problèmes techniques concernant les ADs</h4>
            </div>
            <div className="ourBestServicesRow beffect">
            <Slider {...settings} className="row">
                <Col sm={4} xs={12}>
                  <Card data-aos="fade-up">
                    <div className="bimg">
                      <Card.Img
                        className="img-fluid mx-auto"
                        variant="top"
                        src={ServicesCard1}
                      />
                    </div>
                    <Card.Body>
                      <Card.Title>Dedicate Team Member</Card.Title>
                      <Card.Text>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since.
                      </Card.Text>
                      <button className="homeBannerBtn ourSurvice">
                        <Link to="/faq">Read More</Link>
                      </button>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={4} xs={12}>
                  <Card data-aos="fade-up">
                  <div className="bimg">
                    <Card.Img
                      className="img-fluid mx-auto"
                      variant="top"
                      src={ServicesCard2}
                    />
                    </div>
                    <Card.Body>
                      <Card.Title>Fast Working Process</Card.Title>
                      <Card.Text>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since.
                      </Card.Text>
                      <button className="homeBannerBtn ourSurvice">
                        <Link to="/faq">Read More</Link>
                      </button>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={4} xs={12}>
                  <Card data-aos="fade-up">
                  <div className="bimg">
                    <Card.Img
                      className="img-fluid mx-auto"
                      variant="top"
                      src={ServicesCard3}
                    />
                    </div>
                    <Card.Body>
                      <Card.Title>Expert Consultants</Card.Title>
                      <Card.Text>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since.
                      </Card.Text>
                      <button className="homeBannerBtn ourSurvice">
                        <Link to="/faq">Read More</Link>
                      </button>
                    </Card.Body>
                  </Card>
                </Col>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
