import React from 'react'
import {
    TextField,
  } from "@material-ui/core";
const FormEtape3 = () => {
  return (
    
    <>
    <p> 3 - Personal Information: </p>
      <TextField
        id="address1"
        label="Full Name"
        variant="standard"
        placeholder="Enter Your first Name"
        fullWidth
        margin="normal"
        name="address1"
      />
      <TextField
        id="country"
        label="Email"
        variant="standard"
        placeholder="Enter Your Email"
        fullWidth
        margin="normal"
        name="country"
      />
       <TextField
        id="country"
        label="Phone"
        variant="standard"
        placeholder="Enter Your Phone"
        fullWidth
        margin="normal"
        name="country"
      />
       <TextField
        id="country"
        label="Enter Your Message"
        variant="standard"
        placeholder="Enter Your Message"
        fullWidth
        margin="normal"
        name="country"
      />
    </>
  )
}

export default FormEtape3