import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import OurMissionImg from "../../../assets/Images/Icon/image_processing20201105-595-1kvfeq7.gif";
import { Link } from "react-router-dom";
export default class OurMission extends Component {
  render() {
    return (
      <>
        <div className="container">
          <div className="mainAboutUs">
            <Row className="align-items-center">
            <Col xl={6} lg={6} data-aos="fade-up">
                <div className="aboutUs">
                  <div className="titleFont">
                    <h2>
                    Our Mission 
                    </h2>
                    <h4>Solution of technical problems concerning ADs</h4>
                    <h5>
                    Our agency is committed to improving your advertising campaigns, to solving ADs problems in order to boost your positioning.
                    </h5>
                  </div>
                  <button className="homeBannerBtn ourSurvice">
                    <Link to="/aboutus">Get Started </Link>
                  </button>
                </div>
              </Col>
              <Col xl={6} lg={6} data-aos="fade-up">
                <img src={OurMissionImg} width="100%" />
              </Col>
              
            </Row>
          </div>
        </div>
      </>
    );
  }
}
