import React, { useState,useEffect } from "react";
import "../../../theme/css/Form.css"
import {
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormEtape1 from "./FormEtape1";
import FormEtape2 from "./FormEtape2";
import FormEtape3 from "./FormEtape3";
import FormEtape4 from "./FormEtape4";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Problem related with",
    "Url",
    "Personal Information",
    "Payment"
  ];
}

function getStepContent(step,spinner,succes) {
  switch (step) {
    case 0:
      return (
        <>
           <FormEtape1 />
        </>
      );
    case 1:
      return (
        <FormEtape2 spinner={spinner} succes={succes}/>
      );
    case 2:
      return (
        <FormEtape3 />
      );
    case 3:
      return (
        <FormEtape4 />
      );
    default:
      return "unknown step";
  }
}

const LinaerStepper = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const [spinner,setSpinner]=useState(false)
  const [succes,setSuccess]=useState(false)
  const steps = getSteps();
  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };
  useEffect( () => {
    console.log(spinner);
}, [spinner,succes]);

  const handleNext = () => {
    if(activeStep==1){
      setSpinner(true);
      setTimeout(() => {
        setSpinner(false);
        setSuccess(true)
      }, 10000);
      setTimeout(() => {
      setSuccess(false)
        setActiveStep(activeStep + 1);
        setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep));
      }, 12000);
    }
    else{
      setActiveStep(activeStep + 1);
        setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep));
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
 

  return (
    <div>
      <Stepper alternativeLabel activeStep={activeStep} className="bg-tranpare">
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

          {activeStep === steps.length ? (
            <Typography variant="h3" align="center">
              Thank You
            </Typography>
          ) : (
        <>
        <form>{getStepContent(activeStep,spinner,succes)}</form>
          <div className="mx-auto text-center mt-3">
              <Button
                className={classes.button}
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                back
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                disabled={spinner || succes }
                color="primary"
                onClick={handleNext}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default LinaerStepper;
