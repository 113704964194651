import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Icon1 from "../../../assets/Images/WeProvide/Icon1.png";
import Icon2 from "../../../assets/Images/WeProvide/Icon2.png";
import Icon3 from "../../../assets/Images/WeProvide/Icon3.png";
import '../../../theme/css/Time.css'

import Aos from "aos";
import "aos/dist/aos.css";
export default function Time() {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  return (
    <>
      <div className="container">
        <div className="weProvide spacing">
          <Row className="weProvideRow">
              <div className="titleFont text-center">
                <h2>Processing time</h2>
                <h3>Dear customer it depends on the nature of your technical problem the processing time differs and varies between 3 levels</h3>
              </div>
            
          </Row>
          <Row data-aos="zoom-in">
            <Col xl={4} lg={4} md={4} sm={6}>
              <div className="weProBox">
                <div className="weProBoxImg">
                  <img
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={Icon1}
                  />
                </div>
                <div className="weProBoxText">
                  <h6>Level 1</h6>
                  <p className="paragraph">
                  Processing time takes between 24 and 48 hours
                  </p>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={6}>
              <div className="weProBox">
                <div className="weProBoxImg">
                  <img
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={Icon2}
                  />
                </div>
                <div className="weProBoxText">
                  <h6>Level 2</h6>
                  <p className="paragraph">
                  Processing time takes between 48 and 72 hours
                  </p>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={6}>
              <div className="weProBox">
                <div className="weProBoxImg">
                  <img
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={Icon3}
                  />
                </div>
                <div className="weProBoxText">
                  <h6>Level 3</h6>
                  <p className="paragraph">
                  Processing time takes between 72 and 10 days
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
