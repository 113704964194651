import { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Button, Collapse } from "react-bootstrap";
import ThemeLogo from "../../assets/Images/BrandLogo/Logo.png";
import ImageMeta from "../../assets/Images/Partner/meta.png";

export const Footer = () => {
  const [Useopen, UsesetOpen] = useState(false);
  const [Copen, CsetOpen] = useState(false);
  return (
    <>
      <div className="footer">
        <Container>
          <Row className="Footerbox justify-content-between">
            <Col xl={5} lg={5} md={4}>
              <div className="F-col-1 footer-text">
                <img
                  src={ThemeLogo}
                  width="160px"
                  alt="themeLogo"
                  className="img-fluid mx-auto WLogo"
                />
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the dummy text ever
                  since the 1500s.Lorem Ipsum is simply dummy text of the
                  printing.
                </p>
              </div>
            </Col>
            <Col xl={2} lg={2} md={2}>
              <div className="F-col-3 footer-text FooterVertical">
                <h3 className=" footerLinkHeading">
                  Services
                  <Button
                    className="Footer-button"
                    onClick={() => CsetOpen(!Copen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Copen}
                  ></Button>
                </h3>
                <Collapse in={Copen}>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/service">Help</Link>
                    </li>
                    <li>
                      <Link to="/faq">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/planing">Terms of use</Link>
                    </li>
                    <li>
                      <Link to="/contact">Support</Link>
                    </li>
                  </ul>
                </Collapse>
              </div>
            </Col>
            <Col xl={3} lg={3} md={4}>
              <div className="F-col-4 footer-text">
                <h3>Partner</h3>
                <div>
                  <img src={ImageMeta} width="100%" /> 
                </div>
                <ul className="list-unstyled social-icon text-center">
                  <li className="d-inline-block">
                    <a href="/" className="d-block">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a href="/" className="d-block">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-bottom">
        <h6 className="F-copywrite">Copyright © 2022 . All Rights Reserved</h6>
      </div>
    </>
  );
};
export default Footer;
