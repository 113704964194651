import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import About1 from "../../../assets/Images/AboutUs/About1.png";
import About2 from "../../../assets/Images/AboutUs/About2.png";
import AboutSlider1 from "../../../assets/Images/AboutUs/AboutSlider1.png";
import AboutSlider2 from "../../../assets/Images/AboutUs/AboutSlider2.png";
import AboutSlider3 from "../../../assets/Images/AboutUs/AboutSlider3.png";
import AboutSlider4 from "../../../assets/Images/AboutUs/AboutSlider4.png";
import { Link } from "react-router-dom";
const settings = {
  dots: false,
  arrows: true,
  slide: ".slick-slideshow__slide",
  slidesToShow: 1,
  centerMode: true,
  centerPadding: "110px",
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 2,
        centerMode: false,
      },
    },
    {
      breakpoint: 800,
      settings: {
        centerMode: false,
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        centerMode: false,
        slidesToShow: 2,

      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        centerMode: false,
      },
    },
  ],
};
export default class SimpleSlider extends Component {
  render() {
    return (
      <>
        <div className="container">
          <div className="mainAboutUs">
            <Row>
              <Col xl={6} lg={6} data-aos="fade-up">
                <div className="aboutSliderDiv">
                <Slider className="aboutSlider" {...settings}>
                  <div>
                    <img
                      className="img-fluid mx-auto"
                      src={AboutSlider2}
                      alt="AboutSlider"
                    />
                  </div>
                  <div>
                    <img
                      className="img-fluid mx-auto"
                      src={AboutSlider3}
                      alt="AboutSlider"
                    />
                  </div>
                  <div>
                    <img
                      className="img-fluid mx-auto"
                      src={AboutSlider1}
                      alt="AboutSlider"
                    />
                  </div>
                  <div>
                    <img
                      className="img-fluid mx-auto"
                      src={AboutSlider4}
                      alt="AboutSlider"
                    />
                  </div>
                </Slider>
                </div>
              </Col>

              <Col xl={6} lg={6} data-aos="fade-up">
                <div className="aboutUs">
                  <div className="titleFont">
                    <h2>OUR SERVICES ARE DEPLOYED</h2>
                    <h3>in north africa and middle east countries </h3>
                    <h5>
                    Today we have exclusivity in the MENA region we are the only ones to offer you the solution to your facebook Ads problems with profssionalism
                    </h5>
                  </div>
                  <div className="aboutIconMainDivFloat">
                    <div className="aboutIconMainDiv">
                      <div className="aboutIconCircle">
                        <img
                          className="img-fluid mx-auto"
                          src={About1}
                          alt="OurFeature"
                        />
                      </div>
                    </div>
                    <div className="aboutUsIconSideText">
                      <h6> Research & Advisory </h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing is and
                        is all typesetting industry. 
                      </p>
                    </div>
                  </div>
                  <div className="aboutIconMainDivFloat">
                    <div className="aboutIconMainDiv">
                      <div className="aboutIconCircle">
                        <img
                          className="img-fluid mx-auto"                        
                          src={About2}
                          alt="OurFeature"
                        />
                      </div>
                    </div>
                    <div className="aboutUsIconSideText">
                      <h6> Consulting & Insights </h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and is
                        all typesetting industry.
                      </p>
                    </div>
                  </div>
                  <button className="homeBannerBtn ourSurvice">
                    <Link to="/aboutus">Get Started </Link>
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}
