import React from 'react'
import Feature1 from "../../../assets/Images/OurFeatures/facebook.png";
import Feature2 from "../../../assets/Images/OurFeatures/team-1.png";
import Feature3 from "../../../assets/Images/OurFeatures/AdAccount.png"
import Feature4 from "../../../assets/Images/OurFeatures/user.png"
const FormEtape1 = () => {
  return (
         <div className="row custoumForm">
              <p className="">1 -What kind of issue are you facing ?</p>
              <p className="">choose 1 of these choices :</p>
              <div className="col-md-6 col-lg-6 col-sm-6">
                <label>
                  <input type="radio" name="problemWith" value="0" className="card-input-element" />
                    <div className="panel panel-default card-input">
                      <div className="panel-heading">
                        <img src={Feature1} width="50px" />
                      </div>
                      <div className="panel-body mt-3">
                          Facebook page <br /> restricted
                      </div>
                    </div>
                </label>
             </div>
             <div className="col-md-6 col-lg-6 col-sm-6">
                <label>
                  <input type="radio" name="problemWith" value="1" className="card-input-element" />
                    <div className="panel panel-default card-input">
                    <div className="panel-heading">
                        <img src={Feature2}   width="50px" />
                      </div>
                      <div className="panel-body mt-3">
                          Disabled Business <br /> Manager
                      </div>
                    </div>
                </label>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6">
                <label>
                  <input type="radio" name="problemWith" value="2" className="card-input-element" />

                    <div className="panel panel-default card-input">
                      <div className="panel-heading">
                        <img src={Feature3} width="50px"  />
                      </div>
                      <div className="panel-body mt-3">     
                        Disabled Ad account
                      </div>
                    </div>
                </label>
               </div>
               <div className="col-md-6 col-lg-6 col-sm-6">
                <label>
                  <input type="radio" name="problemWith" value="3" className="card-input-element" />

                    <div className="panel panel-default card-input">
                    <div className="panel-heading">
                        <img src={Feature4}  width="50px" />
                      </div>
                      <div className="panel-body mt-3">
                        Profile
                      </div>
                    </div>
                </label>
               </div>
          </div>
  )
}

export default FormEtape1