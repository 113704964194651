import React from 'react'
import succesImage from "../../../assets/Images/Icon/1818-success-animation.gif"
import {
    TextField,
    CircularProgress 
  } from "@material-ui/core";

const FormEtape2 = ({spinner,succes}) => {
  return (
    <>
        <p> 2 - Enter your URL or Facebook ID : </p>
          <TextField
            id="email"
            label="Enter Url"
            variant="standard"
            placeholder="Enter Url"
            fullWidth
            margin="normal"
            key = '3'
            name="url"
          />
          {
            spinner ? 
            <div className="d-block mx-auto text-center my-5">
              <CircularProgress />
              <p>Please,wait... Our technical team is checking your case</p>
            </div>
            :''
          }
          {
            succes ? 
            <div className="d-block mx-auto text-center my-5">
              <img src={succesImage} width='25%' />
              <p>Cette Page Peut etre Reactiver</p>
            </div>
            :''
          }
        </>
  )
}

export default FormEtape2