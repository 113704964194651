import React,{useEffect} from "react";
import "../../../../src/theme/css/HomePage.css";
import { Row, Col } from "react-bootstrap";
import Feature1 from "../../../assets/Images/OurFeatures/icons8-facebook-50.png";
import Feature2 from "../../../assets/Images/OurFeatures/icons8-business-network-30.png";
import Feature3 from "../../../assets/Images/OurFeatures/icons8-remove-ads-50.png";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
export default function OurFeature() {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  return (
    <>
      <div className="container">
        <div className="ourFeature">
          <div className="titleFont text-center" data-aos="zoom-in-down">
            <h3>with issues related to :</h3>
            <h2>OUR SERVICES DEAL</h2>
          </div>
          <div className="mainFeaturesCard">
            <Row>
              <Col xs={12} sm={4}>
                <div className="featuresCard mt-5"  data-aos="zoom-in">
                  <div className="featuresCardCircle">
                    <img className="img-fluid mx-auto" src={Feature1} alt="OurFeature" />
                  </div>
                  <div className="featuresCardContain">
                    <h3>Facebook page restricted</h3>
                    <h6>
                    We have the possibility to solve these problems, which have many advantages, especially after the service that we offer you with professionalism.
                    </h6>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="featuresCard mt-5"  data-aos="zoom-in">
                  <div className="featuresCardCircle">
                    <img className="img-fluid mx-auto" src={Feature2} alt="OurFeature" />
                  </div>
                  <div className="featuresCardContain">
                    <h3>Disabled Business Manager</h3>
                    <h6>
                    Solutions and methods that will enable you to recover closed pages, your ad accounts and your business manager.
                    </h6>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="featuresCard mt-5"  data-aos="zoom-in">
                  <div className="featuresCardCircle">
                    <img className="img-fluid mx-auto" src={Feature3} alt="OurFeature" />
                  </div>
                  <div className="featuresCardContain">
                    <h3> Disabled Ad account</h3>
                    <h6>
                    All this only with the id of the page, ad account ID or business manager ID.
                    we give you the opportunity to get the solution
                    </h6>
                  </div>
                </div>
              </Col>
            </Row>
            <button className="homeBannerBtn ourSurvice"><Link to="/service">Get Started </Link></button>
          </div>
        </div>
      </div>
    </>
  );
}
