import React from "react";
import Banner from "../HomePage/Banner"
import OurFeature from "../HomePage/OurFeature"
import AboutUs from '../HomePage/AboutUs'
import OurBestServices from "./OurBestServices";
import OurTestimonial from "./OurTestimonial";
import ContactUs from "./ContactUs";
import Time from "./Time";
import OurMission from "./OurMission";
export default function Home() {
  return (
    <>
      <Banner/>
      <OurFeature/>
      {/* <YearOfExperience/> */}
      <AboutUs/>
      <OurMission />
      <OurBestServices/>
      <Time/>
      {/* <OurPricing/> */}
      <OurTestimonial/>
      <ContactUs/>  
      {/* <OurBlog/> */}
      {/* <Brandlogo/> */}
      {/* <StartedToday/> */}
      
    </>
  );
}
