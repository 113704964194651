import React from "react";
import "../../../../src/theme/css/HomePage.css";
import Banner from "../../../assets/Images/Banner/header 33.jpg";
import { Link } from "react-router-dom";
export default function Home() {
  return (
    <>
      <div className="mainBannerDiv">
        <div className="container">
          <div className="bannerContain text-uppercase" data-aos="zoom-in">
            <h1 className="text-dark mb-0">You advertise </h1>
            <h1>We support</h1>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Consequuntur, unde nobis aspernatur deserunt sunt sit odio nisi
              necessitatibus saepe enim adipisci reprehenderit nam expedita
              minima.
            </p>
            <button className="button ourSurvice mt-3 border-0 px-5 text-uppercase">
              <Link to="/service">Get Started </Link>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
