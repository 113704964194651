import React from 'react'
import Feature1 from "../../../assets/Images/Icon/bank.png";
import Feature2 from "../../../assets/Images/OurFeatures/team-1.png";
import Feature3 from "../../../assets/Images/OurFeatures/AdAccount.png"
import Feature4 from "../../../assets/Images/OurFeatures/user.png"
const FormEtape4 = () => {
  return (
         <div className="row custoumForm">
              <p className="">4 - Price In Your Case : 240 $</p>
              <p className="">And Select Method Of Payment :</p>
              <div className="col-md-6 col-lg-6 col-sm-6">
                <label>
                  <input type="radio" name="payment" value="0" className="card-input-element" />
                    <div className="panel panel-default card-input">
                      <div className="panel-heading">
                        <img src={Feature1} width="50px" />
                      </div>
                      <div className="panel-body mt-3">
                        Wire Transfer
                      </div>
                    </div>
                </label>
             </div>
             <div className="col-md-6 col-lg-6 col-sm-6">
                <label>
                  <input type="radio" name="payment" value="1" className="card-input-element" />
                    <div className="panel panel-default card-input">
                    <div className="panel-heading">
                        <img src={Feature2}   width="50px" />
                      </div>
                      <div className="panel-body mt-3">
                            Wise
                      </div>
                    </div>
                </label>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6 mx-auto">
                <label>
                  <input type="radio" name="payment" value="2" className="card-input-element" />

                    <div className="panel panel-default card-input">
                      <div className="panel-heading">
                        <img src={Feature3} width="50px"  />
                      </div>
                      <div className="panel-body mt-3">     
                          CIH
                      </div>
                    </div>
                </label>
               </div>
             
          </div>
  )
}

export default FormEtape4