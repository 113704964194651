import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../components/pages/HomePage/Home";
import Form from "../components/pages/HomePage/Form";


export default function Index() {
 
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/form" element={<Form />} />
      </Routes>
     </div>
  );
}
